// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-human-design-inner-authority-tsx": () => import("./../../../src/pages/human-design/inner-authority.tsx" /* webpackChunkName: "component---src-pages-human-design-inner-authority-tsx" */),
  "component---src-pages-human-design-the-four-types-tsx": () => import("./../../../src/pages/human-design/the-four-types.tsx" /* webpackChunkName: "component---src-pages-human-design-the-four-types-tsx" */),
  "component---src-pages-human-design-the-nine-centers-tsx": () => import("./../../../src/pages/human-design/the-nine-centers.tsx" /* webpackChunkName: "component---src-pages-human-design-the-nine-centers-tsx" */),
  "component---src-pages-human-design-tsx": () => import("./../../../src/pages/human-design.tsx" /* webpackChunkName: "component---src-pages-human-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

